<template>
  <div class="pui-thumbnail pui-thumbnail__root">
    <pui-card
      :style="{
        height: `calc(100% - ${metaHeight}px)`,
      }"
      :class="{ 'pui-thumbnail__card': true, clickable, active }"
      v-on="$listeners"
    >
      <template #default>
        <div class="pui-thumbnail__main-content">
          <div class="pui-thumbnail__overlay"></div>
          <pui-image-viewer
            :src="src"
            :mode="Mode.SRC"
            :classSuccess="{ 'pui-thumbnail__img': true }"
            class="pui-thumbnail__image-viewer"
          >
            <template #error>
              <svg viewBox="0 0 24 24" class="pui-thumbnail__default-thumbnail">
                <path
                  d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z"
                />
              </svg>
            </template>
            <template #placeholder>
              <pui-flex
                class="pui-thumbnail__loader-container"
                justifyContent="center"
                alignItems="center"
              >
                <pui-ball-loader></pui-ball-loader>
              </pui-flex>
            </template>
          </pui-image-viewer>
        </div>
      </template>

      <template #center>
        <slot name="center"></slot>
      </template>

      <template v-if="checkable || checked" #topLeft>
        <pui-checkbox
          :class="{ 'pui-thumbnail__checkbox': true, checked }"
          :checked="checked"
          :indeterminate="indeterminate"
        ></pui-checkbox>
      </template>

      <template #bottomRight>
        <slot name="actions"></slot>
      </template>
    </pui-card>
    <pui-flex
      v-if="showTitle || showSubtitle"
      :style="{ height: `${metaHeight}px` }"
      class="pui-thumbnail__meta"
      justifyContent="center"
      direction="column"
    >
      <div v-if="showTitle">
        <slot name="title"> </slot>
      </div>
      <div v-if="showSubtitle">
        <slot name="subtitle"> </slot>
      </div>
    </pui-flex>
  </div>
</template>

<script>
import { Mode } from 'piivo-ui/src/components/image/ImageViewer';

export const META_HEIGHT_PX = 22;

export default {
  name: 'PuiThumbnail',
  props: {
    /**
     * Image src for the thumbnail
     */
    src: {
      type: String,
      default: '',
    },
    /**
     * If the checkbox should be displayed
     */
    checkable: {
      type: Boolean,
      default: false,
    },
    /**
     * Activates the checked state of the checkbox.
     */
    checked: {
      type: Boolean,
      default: false,
    },
    /**
     * Activates the indeterminate state of the checkbox.
     */
    indeterminate: {
      type: Boolean,
      default: false,
    },
    /**
     * Styles the whole thumbnail as clickable. Enable this if you render
     * the center slot
     */
    clickable: {
      type: Boolean,
      default: false,
    },
    /**
     * Styles the thumbnail as "active"
     */
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * Renders the "title" slot
     */
    showTitle: {
      type: Boolean,
      default: true,
    },
    /**
     * Renders the "subtitle" slot
     */
    showSubtitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Mode,
    };
  },
  computed: {
    /**
     * @returns {number} the height of the meta container
     */
    metaHeight() {
      return [this.showTitle, this.showSubtitle].filter(Boolean).length * META_HEIGHT_PX;
    },
  },
};
</script>
