import { PersistKeys } from '../plugins/persistence';

export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_LAST_LOGIN_TYPE = 'SET_LAST_LOGIN_TYPE';

export const CoreModule = {
  state: {
    auth: {
      [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
      isLoggedIn: false,
      accessToken: null,
      refreshToken: null,
      lastLoginType: null,
      issued: null,
      expires: null,
    },
    user: {
      [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
      name: null,
      language: null,
      extendedProperties: {},
      permissions: [],
    },
  },
  getters: {
    user: (state) => state.user,
    lastLoginType: (state) => state.auth.lastLoginType,
  },
  actions: {
    /**
     * Clear each property, one by one, so reactivity still works.
     *
     * (ie. clear out state.auth.isLoggedIn so Navbar component automatically reacts to logged out state,
     * and the Navbar menu adjusts accordingly)
     *
     * TODO: use a common import of default state to reset these values with.
     */
    [USER_LOGOUT](context) {
      // Auth
      context.state.auth.isLoggedIn = false;
      context.state.auth.accessToken = null;
      context.state.auth.refreshToken = null;

      // User
      context.state.user.userName = '';
      context.state.user.displayName = '';
      context.state.user.language = 'fr_FR';
      context.state.user.extendedProperties = {};
      context.state.user.permissions = [];
    },
  },
  mutations: {
    [UPDATE_AUTH](state, auth) {
      state.auth = auth;
    },
    [UPDATE_USER](state, user) {
      state.user = user;
    },
    [SET_LAST_LOGIN_TYPE](state, type) {
      state.auth.lastLoginType = type;
    },
  },
};
