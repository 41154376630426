import { USER_LOGOUT } from '../../modules/coreModule';
import { PersistKeys } from '.';

/**
 * The key that every module can set to "true" in their local state
 * to mark that they should be kept in localStorage, and preserved
 * when the user's data is cleared
 */
export const PRESERVE_STATE_ON_CLEAR = '__preserve_state_on_clear__';

// Set the key we'll use in local storage.
// Go to Chrome dev tools, application tab, click "Local Storage" and "http://localhost:8080"
// and you'll see this key set below (if logged in):
const STORAGE_KEY = 'piivo';

/**
 * Saves the global state to localStorage
 *
 * @param {*} state - store global state
 */
const saveState = (state) => {
  const dataToSave = {};

  // Only save states that are marked to preserve
  Object.entries(state).forEach(([moduleName, moduleState]) => {
    if (moduleState[PRESERVE_STATE_ON_CLEAR]) {
      dataToSave[moduleName] = moduleState;
    }
  });

  localStorage.setItem(STORAGE_KEY, JSON.stringify(dataToSave));
};

/**
 * @param {*} mutation - mutation object
 * @param {*} state - store global state
 */
const mutationListener = (mutation, state) => {
  // Save data on every mutation
  saveState(state);
};

/**
 * @param {*} action - action object
 * @param {*} state - store global state
 */
const actionListener = (action, state) => {
  // On logout, re-save state in localStorage
  if (action.type === USER_LOGOUT) {
    // Overwriting the state will "clear" all non persistent modules
    // as a side effect
    saveState(state);
  }
};

/**
 * Local storage plugin to sync store state with local storage
 *
 * @param {*} vuexStore - vuex store
 */
export const localStoragePlugin = (vuexStore) => {
  // Sync with local storage on store initialization
  if (localStorage.getItem(STORAGE_KEY)) {
    const notSyncedData = JSON.parse(localStorage.getItem(STORAGE_KEY));
    const mergedState = {
      ...vuexStore.state,
      // It's OK if some state is replaced since store.state only contains default state
      ...notSyncedData,
      [PersistKeys.STATE_IS_RESTORED]: true,
    };

    vuexStore.replaceState(mergedState);
  }

  vuexStore.subscribe(mutationListener);
  // Subscribe after all action handlers
  vuexStore.subscribeAction({ after: actionListener });
};
