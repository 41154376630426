import { Response } from '../../../core/types/http';
import bulkApi, { BulkActionDetailsBase, BulkActionStatus } from '../api/bulk';

export interface BulkActionCallbackManager<Details extends BulkActionDetailsBase, Err = Error> {
  /**
   * When the bulk action is created.
   */
  onStatusNew?: (bulkDetails: Details) => void;
  /**
   * When the bulk action is queued.
   */
  onStatusEnqueued?: (bulkDetails: Details) => void;
  /**
   * When the bulk action is started.
   */
  onStatusStarted?: (bulkDetails: Details) => void;
  /**
   * When the bulk action is finished.
   */
  onStatusFinished?: (bulkDetails: Details) => Promise<void> | void;
  /**
   * When the bulk action fails
   */
  onStatusInError?: (bulkDetails: Details) => void;
  /**
   * When the polling encounters an error. Not the bulk action
   */
  onPollError?: (error: Err) => void;
}

export interface PollOptions<Details = BulkActionDetailsBase> {
  /**
   * Async function to retrieve the details of bulk action: api response
   */
  getBulkActionDetails: (bulkActionId: string) => Response<Details>;
  /**
   * The time to wait until the next poll
   */
  nextPollTimeout: number;
}

/**
 * Maps the possible bulk action statuses to the callbacks
 */
const StatusToCallbackMap: { [x: string]: string } = {
  [BulkActionStatus.NEW]: 'onStatusNew',
  [BulkActionStatus.ENQUEUED]: 'onStatusEnqueued',
  [BulkActionStatus.STARTED]: 'onStatusStarted',
  [BulkActionStatus.FINISHED]: 'onStatusFinished',
  [BulkActionStatus.INERROR]: 'onStatusInError',
};

/**
 * Status for which to consider the bulk action done, and thus end polling
 */
const EndPollBulkStatuses = [BulkActionStatus.FINISHED, BulkActionStatus.INERROR];

/**
 * Default options for polling
 */
export const DefaultPollOptions: PollOptions = {
  nextPollTimeout: 5 * 1000,
  getBulkActionDetails: async (bulkActionId: string) => bulkApi.getBulkActionDetails(bulkActionId),
};

/**
 * Service to poll bulk actions
 */
export class BulkActionService {
  /**
   * Checks the status of a bulk action.
   * If Finished or InError, will stop polling.
   * Otherwise will just set a timeout for another check
   *
   * @param bulkActionId - the bulk action to check
   * @param callbackManager - object of callback functions
   * @param pollOptions - options for the polling
   */
  static async pollBulkAction<Details extends BulkActionDetailsBase, Err = Error>(
    bulkActionId: string,
    callbackManager: BulkActionCallbackManager<Details, Err>,
    pollOptions?: Partial<PollOptions<Details>>
  ): Promise<void> {
    const mergedOptions = { ...DefaultPollOptions, ...(pollOptions ?? {}) } as PollOptions<Details>;

    try {
      const res = await mergedOptions.getBulkActionDetails(bulkActionId);

      const bulkDetails = res.body;
      const { status } = bulkDetails;

      // Execute the callback associated to the status
      const callback = (callbackManager as any)[StatusToCallbackMap[status]] as (
        arg: unknown
      ) => Promise<void> | void;
      if (callback) {
        // Invoke the callback, using the manager as 'this'
        void callback.call(callbackManager, bulkDetails);
      }

      // If the action is 'ended', stop polling
      if (EndPollBulkStatuses.includes(status)) {
        return;
      }
    } catch (err) {
      if (callbackManager.onPollError) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        callbackManager.onPollError(err as any);
        // Continue execution
      }
    }

    // If the status did not correspond to an end state, continue polling
    setTimeout(() => {
      void BulkActionService.pollBulkAction(bulkActionId, callbackManager, mergedOptions);
    }, mergedOptions.nextPollTimeout);
  }
}
