import Vue from 'vue';

import { USER_LOGOUT } from '../../core/store/modules/coreModule';
import { PersistKeys } from '../../core/store/plugins/persistence';

export const NAMESPACE = 'applications';

export const SET_LOADING_MODULES = 'SET_LOADING_MODULES';
export const SET_ERROR_GENERAL = 'SET_ERROR_GENERAL';

export const SET_CURRENT_APP_ALIAS = 'SET_CURRENT_APP_ALIAS';

export const REMOVE_APP_DETAILS = 'REMOVE_APP_DETAILS';
export const CLEAR_ALL_APPS = 'CLEAR_ALL_APPS';

export const SET_LOADING_APP = 'SET_LOADING_APP';
export const SET_ERROR_APP = 'SET_ERROR_APP';

export const SET_APP_DETAILS = 'SET_APP_DETAILS';

const APP_STATE_KEY_PREFIX = 'app_details_';

const getAppStateKey = (appAlias) => `${APP_STATE_KEY_PREFIX}${appAlias}`;

const extractAppStateKey = (detailsStateName) => detailsStateName.replace(APP_STATE_KEY_PREFIX, '');

const getNewState = () => ({
  [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
  loadingModules: true,
  error: false,
  currentApplicationAlias: null,
});

/**
 * App details store module
 */
export const ApplicationsModule = {
  namespaced: true,
  state: { ...getNewState() },

  getters: {
    // GENERAL
    /**
     * Gets the general modules loading state
     */
    isLoadingModules: (state) => () => {
      return state.loadingModules;
    },
    /**
     * Gets the general error state
     */
    hasError: (state) => () => {
      return state.error;
    },
    /**
     * Gets the current application id
     */
    currentApplicationAlias: (state) => () => {
      return state.currentApplicationAlias;
    },
    // APPS
    getCurrentAppDetails: (state) => () => {
      if (!state.currentApplicationAlias) {
        return null;
      }

      const appStateKey = getAppStateKey(state.currentApplicationAlias);

      const appState = state[appStateKey];
      return appState ? appState.details : null;
    },
    getAppDetails: (state) => (appAlias) => {
      const appStateKey = getAppStateKey(appAlias);

      const appState = state[appStateKey];
      return appState ? appState.details : null;
    },
    /**
     * Gets the app-specific loading state
     */
    isLoadingApp: (state) => (appAlias) => {
      const appStateKey = getAppStateKey(appAlias);

      if (state[appStateKey]) {
        return state[appStateKey].loading;
      }
      return false;
    },
    /**
     * Gets the app-specific error state
     */
    appHasError: (state) => (appAlias) => {
      const appStateKey = getAppStateKey(appAlias);

      if (state[appStateKey]) {
        return state[appStateKey].error;
      }
      return false;
    },
    /**
     * Gets the error state of any of the apps (not general)
     */
    hasErrorAny: (state, getters) => () => {
      return Object.keys(state).some(
        (subStateKey) =>
          subStateKey.startsWith(APP_STATE_KEY_PREFIX) &&
          getters.appHasError(extractAppStateKey(subStateKey))
      );
    },
    /**
     * Gets the loading state of any of the apps (not general)
     */
    isLoadingAny: (state, getters) => () => {
      return Object.keys(state).some(
        (subStateKey) =>
          subStateKey.startsWith(APP_STATE_KEY_PREFIX) &&
          getters.isLoadingApp(extractAppStateKey(subStateKey))
      );
    },
  },

  actions: {
    [USER_LOGOUT]: {
      root: true,
      /**
       * Clears state.
       *
       * @param {Object} context - namespaced context
       */
      handler(context) {
        const newState = getNewState();
        // Logging out does not reset the loaded modules, keep flag to false
        newState.loadingModules = false;
        Object.entries(context.state).forEach(([key]) => {
          if (Object.hasOwnProperty.call(newState, key)) {
            Vue.set(context.state, key, newState[key]);
          } else {
            Vue.delete(context.state, key);
          }
        });
      },
    },
  },

  mutations: {
    // GENERAL
    /**
     * Sets the general modules loading state
     *
     * @param {object} state - Store State
     * @param {object} payload - the mutation payload
     * @param {boolean} payload.loadingState - the new modules loading state
     */
    [SET_LOADING_MODULES](state, { loadingState }) {
      Vue.set(state, 'loadingModules', loadingState);
    },
    /**
     * Sets the general error state
     *
     * @param {object} state - Store State
     * @param {object} payload - the mutation payload
     * @param {boolean} payload.errorState - the new error state
     */
    [SET_ERROR_GENERAL](state, { errorState }) {
      Vue.set(state, 'error', errorState);
    },
    /**
     * Sets the current application id
     *
     * @param {object} state - Store State
     * @param {object} payload - the mutation payload
     * @param {string} payload.id - the id of the app
     */
    [SET_CURRENT_APP_ALIAS](state, { alias }) {
      Vue.set(state, 'currentApplicationAlias', alias);
    },
    // APP
    /**
     * Sets the app's details as a property of
     * the state under the appAlias key
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.appAlias - the key for the details
     * @param {object} payload.details - the state to save
     */
    [SET_APP_DETAILS](state, { appAlias, details }) {
      const appStateKey = getAppStateKey(appAlias);

      // We need to use use Vue.set because we are adding
      // a property to the root state, and root property additions
      // are not reactive
      if (state[appStateKey]) {
        Vue.set(state[appStateKey], 'details', details);
      } else {
        Vue.set(state, appStateKey, {
          details,
          loading: false,
          error: false,
        });
      }
    },
    /**
     * Removes an app's details
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.appAlias - the key for the details
     */
    [REMOVE_APP_DETAILS](state, { appAlias }) {
      const appStateKey = getAppStateKey(appAlias);

      Vue.delete(state, appStateKey);
    },
    /**
     * Clears all categories
     *
     * @param {object} state - Store State
     */
    [CLEAR_ALL_APPS](state) {
      Object.keys(state).forEach((key) => {
        if (key.startsWith(APP_STATE_KEY_PREFIX)) {
          Vue.delete(state, getAppStateKey(key));
        }
      });
    },
    /**
     * Sets the app loading state
     *
     * @param {object} state - Store State
     * @param {object} payload - the mutation payload
     * @param {string} payload.appAlias - the name of the app whose state to set
     * @param {boolean} payload.loadingState - the new loading state
     */
    [SET_LOADING_APP](state, { appAlias, loadingState }) {
      const appStateKey = getAppStateKey(appAlias);

      if (state[appStateKey]) {
        Vue.set(state[appStateKey], 'loading', loadingState);
      } else {
        Vue.set(state, appStateKey, {
          details: null,
          loading: loadingState,
          error: false,
        });
      }
    },
    /**
     * Sets the app error state
     *
     * @param {object} state - Store State
     * @param {object} payload - the mutation payload
     * @param {string} payload.appAlias - the name of the app whose state to set
     * @param {boolean} payload.errorState - the new error state
     */
    [SET_ERROR_APP](state, { appAlias, errorState }) {
      const appStateKey = getAppStateKey(appAlias);

      if (state[appStateKey]) {
        Vue.set(state[appStateKey], 'error', errorState);
      } else {
        Vue.set(state, appStateKey, {
          details: null,
          loading: false,
          error: errorState,
        });
      }
    },
  },
};
