import Vue from 'vue';

import { Response } from '../../../core/types/http';

const BULK_ACTIONS_API = '/api/piivo/v1/bulkactions';

export enum BulkActionStatus {
  /**
   * Action created on server
   */
  NEW = 'New',
  /**
   * Action queued for execution on server
   */
  ENQUEUED = 'Enqueued',
  /**
   * Action execution started
   */
  STARTED = 'Started',
  /**
   * Action execution finished with success
   */
  FINISHED = 'Finished',
  /**
   * Action execution finished with failure
   */
  INERROR = 'InError',
}

export interface BulkActionDetailsBase {
  status: BulkActionStatus;
}

export interface BulkActionDetails<Result = object> extends BulkActionDetailsBase {
  bulkActionId: string;
  sessionId: string;
  taskId: string;
  taskName: string;
  result: Result;
}

export default {
  /**
   * Get status for a bulk action
   *
   * @param actionId - the id the of the action
   */
  async getBulkActionDetails(actionId: string | number): Response<BulkActionDetailsBase> {
    return Vue.http.get(`${BULK_ACTIONS_API}/${actionId}`) as Response<BulkActionDetailsBase>;
  },
};
