/**
 * Constants file for signage.
 */

// Statutes alias
export const Statutes = {
  TO_BE_PRINTED: 'signages.status.ToBePrinted',
  WAITING_TO_BE_PRINTED: 'signages.status.WaitingToBePrinted',
  PRINTED: 'signages.status.Printed',
};

// Component types
export enum ComponentTypes {
  TEXT = 'text',
  STEP_TEXT = 'steptext',
  RECTANGLE = 'rectangle',
  LINE = 'line',
  PATH = 'path',
  ELLIPSE = 'ellipse',
  IMAGE = 'image',
  BARCODE = 'barcode',
  DATAMATRIX = 'datamatrix',
  STACK_LAYOUT = 'stacklayout',
  WRAP_LAYOUT = 'wraplayout',
  LOOP = 'loop',
  COMPONENT = 'component',
  EMPTY_COMPONENT = 'emptycomponent',
  PRICE = 'price',
  GENERIC_BARCODE = 'generic_barcode',
}

export enum ComponentAnchors {
  LEFT_TOP = 'leftTop',
  MID_TOP = 'midTop',
  RIGHT_TOP = 'rightTop',
  LEFT_MID = 'leftMid',
  CENTER = 'center',
  RIGHT_MID = 'rightMid',
  LEFT_BOTTOM = 'leftBottom',
  MID_BOTTOM = 'midBottom',
  RIGHT_BOTTOM = 'rightBottom',
}

// Default signage source alias (for creation and update)
export const DEFAULT_SIGNAGE_SOURCE = 'signages_sources_piivo_web';

// Attributes alias
export const AttributeAlias = {
  TYPE: 'signages.templates.attributes.type',
  FORMAT: 'signages.templates.attributes.format',
  STATUS: 'signages.signages.attributes.status',
  STORE: 'signages_signages_inputs_store',
  SOURCE: 'signages.signages.attributes.source',
  DEPARTMENT: 'rayon',
  SHOW_ALL_PRINTABLES: 'printAllItems',
};

// Filters alias
export const FilterAlias = {
  TYPE: 'signages_logs_filters_type',
  FORMAT: 'signages_logs_filters_format',
  STORE: 'signages_logs_filters_store',
  USER_DISPLAY_NAME: 'signages_logs_filters_userDisplayName',
  DEPARTMENT: 'signages_logs_filters_department',
};

export enum CreationActions {
  CREATE = 'send',
  DOWNLOAD = 'download',
  PRINT_AUTO = 'print_auto',
}

export enum DemandsActions {
  DOWNLOAD = CreationActions.DOWNLOAD,
  PRINT_AUTO = CreationActions.PRINT_AUTO,
}

export const PosterCreationStates = {
  IN_PROGRESS: 'in_progress',
  DONE_SUCCESS: 'done_success',
  DONE_ERROR: 'done_error',
};

/**
 * The alias of the poster app and the name for internal references
 */
export const POSTER_APP_NAME = 'piivo_portal_application_poster';

/**
 * Namespace for all poster services
 */
export const POSTER_SERVICES_NAMESPACE = 'poster';

/**
 * Minimum amount of copies that can be created per poster
 */
export const MIN_COPIES = 0;

/**
 * Maximum amount of copies that can be created per poster
 */
export const MAX_COPIES = 99;

export enum PosterMimeTypes {
  Poster = 'application/piivo.poster',
}

/**
 * Defines scopes used for POSter
 */
export enum PosterScopes {
  /**
   * Elements to display in simple creation page
   */
  Creation = 'simple_creation',
  /**
   * Elements to display in batch creation page
   */
  BatchCreation = 'batch_creation',
  /**
   * Elements to display for filters in demands page
   */
  Demands = 'demands',
}
