import { ServiceManager } from './plugin';

const services = new ServiceManager();

export default services;

/**
 * @param namespace - service namespace
 * @returns namespaced getters and setters
 */
export function createNamespacedHelpers(this: void, namespace: string) {
  return {
    /**
     * Gets the service from the given namespace
     *
     * @param serviceName - the name of the service
     * @returns the service or null
     */
    getService<T>(this: void, serviceName: string): T | null {
      return services.getNamespacedService(namespace, serviceName);
    },

    /**
     * Adds the service into the given namespace
     *
     * @param serviceName - the name of the service
     * @param service - the service
     */
    addService<T>(this: void, serviceName: string, service: T): void {
      return services.addNamespacedService(namespace, serviceName, service);
    },
  };
}
